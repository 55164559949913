import React, { FC } from "react";
import { Text, useSitecoreContext } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type TextProps as SitecoreTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text';
import Balancer from "react-wrap-balancer";
import parse, { domToReact, Element } from "html-react-parser";

type TextProps = SitecoreTextProps & {
	balance?: boolean;
	tag?: React.ElementType
}

const TextWrapper: FC<TextProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();

	if (!props?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	const fieldValueString = props.field?.value?.toString() || "";

	if (sitecoreContext.pageEditing && props.editable) {
		return <Text {...props} />;
	}

	if (!fieldValueString.includes("<sup>") && !fieldValueString.includes("<sub>")) {
		return <Text {...props} />;
	}

	const parseHtmlContent = (html: string) => {
		const allowedTags = ["sup", "sub"];

		return parse(html, {
			replace: (domNode) => {
				if (domNode instanceof Element) {
					const Tag = domNode.tagName as keyof JSX.IntrinsicElements;

					if (!allowedTags.includes(domNode.tagName.toLowerCase())) {
						return <></>;
					}

					return (
						<Tag className={`text-${domNode.tagName}`}>
							{domToReact(domNode.children)}
						</Tag>
					);
				}
			},
		});
	};

	const content = props?.field?.value ? parseHtmlContent(props?.field?.value?.toString()) : null;

	if (props.balance && !sitecoreContext.pageEditing) {
		return (
			<Balancer as={props.tag || "span"}>
				{content}
			</Balancer>
		);
	}

	const Tag = props.tag || React.Fragment;

	return (
		<Tag>
			{content}
		</Tag>
	);
};

export default TextWrapper;