import React, { FC } from "react";
import { useSitecoreContext } from "~/foundation/Jss";

type LogoProps = {
	height?: number
}

export const LogoLight: FC<LogoProps> = ({ height }) => {
	const { sitecoreContext } = useSitecoreContext();
	const logoLight = sitecoreContext.custom.settings.logoLight;

	return <img src={logoLight} alt="Aramco" width="123" height={height} />
}

export const LogoDark: FC<LogoProps> = ({ height }) => {
	const { sitecoreContext } = useSitecoreContext();
	const logoDark = sitecoreContext.custom.settings.logoDark;

	return <img src={logoDark} alt="Aramco" width="123" height={height} />
}