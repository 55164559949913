import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { SitecoreContextValue } from "~/foundation/Jss";
import { Box, Button, Text as ChakraText, Flex, Input } from "@chakra-ui/react";
import { themable } from "~/foundation/Theme";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { AudioPlayerContext } from "./AudioPlayerProvider";
import { ArticleBase, MagazineBase } from "../generated-types";
import { Icon } from "~/foundation/Components/Icon";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { AudioPlayerStyling } from "./styles";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { AnimatePresence } from "framer-motion";
import { SuitcaseContext } from "~/foundation/Suitcase";

type AudioPlayerProps = {
	sitecoreContext: SitecoreContextValue<MagazineBase & ArticleBase>
}

export const AudioPlayer: FC<AudioPlayerProps> = ({ sitecoreContext }) => {
	const [theme,] = useContext(ColorThemeContext);
	const { isAudioPlayerOpen, setIsAudioPlayerOpen, isPlaying, setIsPlaying, setIsPaused } = useContext(AudioPlayerContext);
	const [suitcase] = useContext(SuitcaseContext);
	const [timeProgress, setTimeProgress] = useState(0);
	const [duration, setDuration] = useState(0);
	const audioRef = useRef<HTMLAudioElement>();
	const progressBarRef = useRef<HTMLInputElement>();
	const actionButtonRef = useRef<HTMLElement>();
	const playButtonRef = useRef<HTMLButtonElement>();
	const playAnimationRef = useRef(0);
	const isRtl = sitecoreContext.custom.settings.isRtl;

	const repeat = useCallback(() => {
		if (audioRef.current && progressBarRef.current && actionButtonRef.current) {
			const currentTime = audioRef.current.currentTime;
			setTimeProgress(currentTime);

			const progress = (Number(progressBarRef.current.value) / duration) * 100;

			progressBarRef.current.value = currentTime.toString();
			progressBarRef.current.style.setProperty(
				'--range-progress',
				`${progress}%`
			);
			actionButtonRef.current.style.transform = isRtl ? `translateX(-${progress}%)` : `translateX(${progress}%)`;

			playAnimationRef.current = requestAnimationFrame(repeat);
		}

	}, [audioRef, progressBarRef, duration]);

	const isSuitcaseOpen = suitcase.length > 0;

	useEffect(() => {
		if (!isPlaying) {
			audioRef.current?.pause();
			setIsPaused && setIsPaused(true);
			cancelAnimationFrame(playAnimationRef.current);
		} else {
			void audioRef.current?.play();
			setIsPaused && setIsPaused(false);
			playAnimationRef.current = requestAnimationFrame(repeat);
		}

	}, [isPlaying, audioRef, repeat]);

	useEffect(() => {
		if (isAudioPlayerOpen && playButtonRef.current) {
			playButtonRef.current.focus();
		}
	}, [isAudioPlayerOpen]);

	const onLoadedMetaData = () => {
		if (audioRef.current && progressBarRef.current) {
			const seconds = audioRef.current.duration;
			setDuration(seconds);
		}
	}

	const formatTime = (time: number) => {
		const mins = (time % 3600) / 60;
		const secs = time % 60;

		return `${Math.floor(mins)}:${secs < 10 ? "0" : ""}${Math.floor(secs)}`;
	}

	const handleProgressChange = () => {
		if (audioRef.current && progressBarRef.current) {
			audioRef.current.currentTime = Number(progressBarRef.current.value);
		}
	};

	const motionVariants = {
		initial: {
			bottom: "-4rem"
		},
		exit: {
			bottom: "-30rem"
		},
		active: {
			bottom: "0rem"
		}
	}

	const transition = {
		default: {
			ease: [.35, 1, .45, 1],
			duration: .5,
		}
	}

	return (
		<AnimatePresence>
			{isAudioPlayerOpen &&
				<MotionBox
					position="fixed"
					bottom="0"
					w="100%"
					variants={motionVariants}
					transition={transition}
					initial={motionVariants.initial}
					exit={motionVariants.exit}
					animate={motionVariants.active}
					data-tracking-type="visibility"
					data-tracking-id="audio_player"
					data-tracking-data={JSON.stringify({ text: sitecoreContext.route?.fields?.title?.value })}
				>
					<Ribbon opacity="0.9" bg="transparent">
						<ContentWrapper py="0" px={['var(--chakra-sizes-pageMarginSM)', null, "16", null, '32']} >
							{sitecoreContext.route!.fields!.articleAudioFile!.value &&
								<Box
									py="14.5px"
									px="6"
									bg={theme.colors.ribbon.bg}
									transitionDuration=".5s"
									transitionProperty="background"
									transitionTimingFunction="aramcoCubic"
									width={isSuitcaseOpen ? ["5rem", null, "calc(100% - (var(--chakra-sizes-suitCaseWidth)))"] : "100%"}
									borderRadius="lg"
									borderBottomRadius="0"
									minH="4rem"
								>
									<audio ref={audioRef as never} onLoadedMetadata={onLoadedMetaData} onEnded={() => {
										cancelAnimationFrame(playAnimationRef.current);
									}}>
										<source src={sitecoreContext.route!.fields!.articleAudioFile!.value.src} type="audio/mpeg" />
									</audio>
									<Flex {...AudioPlayerStyling(isRtl, theme)}>
										<Flex flex={["auto 0 1", null, null, "34% 0 0"]} alignItems="center" pe={[0, null, null, "2rem"]}>
											<Button
												className="audio-player__icon-wrapper"
												display={[isSuitcaseOpen ? "none" : "flex", null, "flex"]}
												pe={["1.5rem", null, null, "1rem"]}
												mt="-1"
												onClick={() => {
													if (isAudioPlayerOpen && setIsAudioPlayerOpen && setIsPlaying) {
														setIsAudioPlayerOpen(false);
														setIsPlaying(false);
														setTimeProgress(0);
													}
												}}
											>
												<Icon variant="Headphones" className="audio-player__headphone-icon" w="1.5625rem" color={theme.colors.ribbon.color} />
												<Icon variant="Close" className="audio-player__close-icon" w="1.5625rem" color={theme.colors.ribbon.color} />
											</Button>
											<ChakraText variant="footerBody" noOfLines={1} display={["none", null, null, "-webkit-box"]}>
												{sitecoreContext.route?.fields?.title?.value}
											</ChakraText>
										</Flex>
										<Flex flex={["auto 1 1", null, null, "66% 0 0"]} alignItems="center">
											<ChakraText size="xxs" me="4" display={[isSuitcaseOpen ? "none" : "block", null, "block"]}>
												{formatTime(timeProgress)}
											</ChakraText>
											<Flex position="relative" w="100%" me="2.1875rem">
												<Box
													transitionDuration=".25s"
													transitionProperty="transform"
													transitionTimingFunction="aramcoCubic"
													position="absolute"
													zIndex="6"
													top="0"
													w="100%"
													ref={actionButtonRef as never}
												>
													<Button
														w="2.1875rem"
														h="2.1875rem"
														background={theme.colors.ribbon.bg}
														transitionDuration=".5s"
														transitionProperty="background"
														transitionTimingFunction="aramcoCubic"
														onClick={() => {
															if (audioRef.current && setIsPlaying) {
																setIsPlaying((prev: boolean) => !prev);
																//audioRef.current.playbackRate = 2;
															}
														}}
														ref={playButtonRef as never}
													>
														<Icon
															variant={isPlaying ? "Pause" : "AudioPlay"}
															transform={isRtl ? "rotate(180deg)" : ""}
															w=".854rem"
															className="icon"
															color={theme.colors.ribbon.color}
															transitionDuration=".75s"
															transitionProperty="color"
															transitionTimingFunction="aramcoCubic"
														/>
													</Button>
												</Box>
												<Input
													type="range"
													ref={progressBarRef as never}
													defaultValue="0"
													onChange={handleProgressChange}
													max={duration}
													height="2.1875rem"
													border="none"
													display={[isSuitcaseOpen ? "none" : "block", null, "block"]}
													_focus={{ "border": "none", "boxShadow": "none" }}
													sx={{
														"&::-webkit-slider-thumb": {
															appearance: "none",
															display: "none"
														},
														"&::-moz-range-thumb": {
															appearance: "none",
															display: "none"
														}
													}}
												/>
											</Flex>

											<ChakraText size="xxs" ms="4" display={[isSuitcaseOpen ? "none" : "block", null, "block"]}>
												-{formatTime(duration - timeProgress)}
											</ChakraText>
										</Flex>
									</Flex>
								</Box>
							}
						</ContentWrapper>
					</Ribbon>
				</MotionBox>
			}
		</AnimatePresence>
	);
};

export default themable()(AudioPlayer);
