/**
 * Used in backend to whitelist sizes
 * When sizes are changed or added, run `npm run build`
 */

/**
 * Values can be:
 * w (width)
 * h (height)
 * mw (max width)
 * mh (max height)
 * cw (crop width)
 * ch (crop height)
 */
export const imageSizes = {
	none: {
		mw: 10,
	},
	s: {
		w: 100
	},
	m: {
		w: 500
	},
	l: {
		w: 1000
	},
	xl: {
		w: 1760
	},
	xxl: {
		w: 2000
	},
	imageModule: {
		default: {
			//16:9
			sm: {
				cw: 500,
				ch: 281,
			},
			md: {
				cw: 1024,
				ch: 576,
			},
			lg: {
				cw: 1312,
				ch: 738,
			},
			xl: {
				cw: 1504,
				ch: 846,
			}
		},
		uncroppedWide: {
			//contentwrapper width - but reduced margins
			sm: {
				w: 500
			},
			md: {
				w: 1024
			},
			lg: {
				w: 1312
			},
			xl: {
				w: 1504
			}
		},
		uncroppedNarrow: {
			//richtext width
			md: {
				w: 500
			},
			lg: {
				w: 710
			},
			xl: {
				w: 826
			},
		}
	},
	heroCarousel: {
		xs: {
			w: 300,
		},
		s: {
			w: 500
		},
		m: {
			w: 1000
		},
		l: {
			w: 1760
		},
		xl: {
			w: 2000
		}
	},
	richpageHeader: {
		//2:3 + extra 20% height for parallax effect => format 2:3.6
		portrait: {
			md: {
				cw: 315,
				ch: 564,
			},
			lg: {
				cw: 427,
				ch: 768,
			},
			xl: {
				cw: 720,
				ch: 1296
			}
		},
		//16:9 + extra 20% height for parallax effect => format 16:10.8
		landscape: {
			md: {
				cw: 1024,
				ch: 691,
			},
			lg: {
				cw: 1312,
				ch: 886,
			},
			xl: {
				cw: 1504,
				ch: 1015,
			}
		}
	},
	imageCarousel: {
		landscape: {
			sm: {
				cw: 300,
				ch: 169
			},
			lg: {
				cw: 672,
				ch: 377
			}
		},
		portrait: {
			sm: {
				cw: 180,
				ch: 270
			},
			lg: {
				cw: 287,
				ch: 430
			}
		},
		square: {
			sm: {
				cw: 250,
				ch: 250
			},
			lg: {
				cw: 316,
				ch: 316
			}
		},
		uncropped: {
			sm: {
				mh: 270
			},
			lg: {
				mh: 530
			}
		}
	},
	teaserGrid: {
		// ratio 3/2
		landscape: {
			sm: {
				cw: 300,
				ch: 200
			},
			lg: {
				cw: 580,
				ch: 387
			}
		},
		// ratio 3/4
		portrait: {
			sm: {
				cw: 180,
				ch: 240
			},
			lg: {
				cw: 279,
				ch: 372
			}
		},
	},
	hero: {
		landscape: {
			sm: {
				cw: 1024,
				ch: 768,
			},
			md: {
				cw: 1024,
				ch: 768,
			},
			lg: {
				cw: 1366,
				ch: 768,
			},
			xl: {
				cw: 1920,
				ch: 1080,
			}
		},
		portrait: {
			sm: {
				cw: 375,
				ch: 667,
			},
			md: {
				cw: 768,
				ch: 1024
			}
		}
	},
	card: {
		portrait: {
			md: {
				cw: 315,
				ch: 470,
			},
			lg: {
				cw: 427,
				ch: 640,
			},
			xl: {
				cw: 720,
				ch: 1080
			}
		}
	},
	generatedCard: {
		landscape: {
			xs: {
				cw: 315,
				ch: 177,
			},
			md: {
				cw: 427,
				ch: 240,
			}
		}
	},
	teaser: {
		landscape: {
			sm: {
				cw: 346,
				ch: 194,
			},
			md: {
				cw: 592,
				ch: 333,
			},
			lg: {
				cw: 880,
				ch: 495,
			}
		},
		square: {
			sm: {
				cw: 482,
				ch: 482,
			},
			md: {
				cw: 592,
				ch: 592,
			},
			lg: {
				cw: 752,
				ch: 752,
			}
		},
		uncroppedSquare: {
			sm: {
				mw: 346,
			},
			md: {
				mw: 592,
			},
			lg: {
				mw: 752,
			}
		},
		uncroppedLandscape: {
			sm: {
				mw: 346,
			},
			md: {
				mw: 592,
			},
			lg: {
				mw: 880,
			}
		},
		squareNoAdvanceImage: {
			sm: {
				mw: 482,
			},
			md: {
				mw: 592,
			},
			lg: {
				mw: 752,
			}
		}
	},
	microCard: {
		landscape: {
			sm: {
				cw: 381,
				ch: 214,
			},
			md: {
				cw: 445,
				ch: 250,
			},
		}
	},
	largeTeaser: {
		square: {
			sm: {
				cw: 375,
				ch: 375,
			},
			md: {
				cw: 767,
				ch: 767,
			},
		},
		landscape: {
			sm: {
				cw: 768,
				ch: 320,
			},
			md: {
				cw: 1024,
				ch: 340,
			},
			lg: {
				cw: 1366,
				ch: 300,
			},
			xl: {
				cw: 1760,
				ch: 300,
			},
			xxl: {
				cw: 1920,
				ch: 300,
			},
		}
	},
	pageHeader: {
		md: {
			cw: 260
		},
		lg: {
			cw: 482
		},
		xl: {
			cw: 760
		}
	},
	logo: {
		sm: {
			mw: 136
		}
	},
	horizontalTimeline: {
		horizontal: {
			//2:3
			sm: {
				cw: 350,
				ch: 525,
			},
			md: {
				cw: 704,
				ch: 396,
			},
		},
		landscape: {
			//16:9
			sm: {
				cw: 1024,
				ch: 576,
			},
			md: {
				cw: 1312,
				ch: 738,
			},
			lg: {
				cw: 1504,
				ch: 846,
			}
		}
	},
	internalScroll: {
		xs: {
			cw: 287,
			ch: 215,
		},
		sm: {
			cw: 450,
			ch: 450,
		},
		md: {
			cw: 677,
			ch: 677,
		}
	},
	promoList: {
		landscape: {
			sm: {
				cw: 380,
				ch: 285,
			},
			md: {
				cw: 592,
				ch: 444,
			},
			lg: {
				cw: 720,
				ch: 540,
			}
		},
		square: {
			sm: {
				cw: 482,
				ch: 362,
			},
			md: {
				cw: 783,
				ch: 620,
			},
			lg: {
				cw: 904,
				ch: 678,
			}
		}
	},
	imageQuote: {
		square: {
			sm: {
				cw: 482,
				ch: 482,
			},
			md: {
				cw: 542,
				ch: 542,
			},
			lg: {
				cw: 703,
				ch: 703,
			}
		}
	},
	quotesOnARow: {
		square: {
			sm: {
				cw: 250,
				ch: 250,
			},
		}
	},
	search: {
		mediaGallery: {
			sm: {
				mw: 350
			},
			md: {
				mw: 740
			}
		},
		publication: {
			sm: {
				mh: 122
			},
			md: {
				mh: 150
			}
		},
		googleSearch: {
			md: {
				mw: 337
			}
		}
	},
	relatedTags: {
		sm: {
			cw: 96,
			ch: 96
		},
		md: {
			cw: 145,
			ch: 145
		}
	},
	scrollStory: {
		portrait: {
			md: {
				cw: 421,
				ch: 846,
			},
		},
		landscape: {
			sm: {
				cw: 1024,
				ch: 576,
			},
			md: {
				cw: 1312,
				ch: 738,
			},
			lg: {
				cw: 1504,
				ch: 846,
			}
		}
	},
	businessModel: {
		md: {
			cw: 654,
			ch: 400,
		},
		xl: {
			cw: 1635,
			ch: 1000,
		},
	},
	mapBoxContent: {
		w: 300
	},
	footerBadge: {
		mw: 135
	}
}