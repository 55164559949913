import React, { FC } from 'react';
import { Global } from '@emotion/react';
import manifapro3Light from '~/foundation/Assets/fonts/ManifaPro/ManifaPro3-Light.woff2';
import manifapro3Bold from '~/foundation/Assets/fonts/ManifaPro/ManifaPro3-SemiBold.woff2';
import ghawarSemiBold from '~/foundation/Assets/fonts/ghawar/ghawar-semibold-v1.woff2';
import ghawarRegular from '~/foundation/Assets/fonts/ghawar/ghawar-regular.woff2';
import ghawarLight from '~/foundation/Assets/fonts/ghawar/Ghawar-Light.woff2';
import haradhBold from '~/foundation/Assets/fonts/Haradh/Haradh-Bold-v1.woff2';
import haradhRegular from '~/foundation/Assets/fonts/Haradh/Haradh-Regular.woff2';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import config from '~/temp/config';

type FontsProps = {
	rtl: boolean;
}

export const Fonts: FC<FontsProps> = ({ rtl }) => {

	// the server bundle loads base64 while the client loads files
	if (!config.connected && !isServer()) {
		return <></>;
	}

	let fontStyle = `
	@font-face {
		font-family: "ManifaPro3";
		src: url("${manifapro3Bold}") format("woff2");
		font-weight: 900;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: "ManifaPro3";
		src: url("${manifapro3Light}") format("woff2");
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}`;

	if (rtl) {
		fontStyle += `
		@font-face {
			font-family: "Haradh";
			src: url("${haradhBold}") format("woff2");
			font-weight: 600;
			font-style: normal;
			font-display: swap;
		}
		@font-face {
			font-family: "Haradh";
			src: url("${haradhRegular}") format("woff2");
			font-weight: 400;
			font-style: normal;
			font-display: swap;
		}`;
	} else {
		fontStyle += `
		@font-face {
			font-family: "Ghawar";
			src: url("${ghawarSemiBold}") format("woff2");
			font-weight: 600;
			font-style: normal;
			font-display: swap;
		}
		@font-face {
			font-family: "Ghawar";
			src: url("${ghawarRegular}") format("woff2");
			font-weight: 400;
			font-style: normal;
			font-display: swap;
		}
		@font-face {
			font-family: "Ghawar";
			src: url("${ghawarLight}") format("woff2");
			font-weight: 300;
			font-style: normal;
			font-display: swap;
		}`;
	}

	return (
		<Global styles={fontStyle} />
	)
}