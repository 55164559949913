import { animations } from "~/foundation/Theme/styles/animations";
import { ColorTheme } from "~/foundation/Theme";

export const MainNavigationStyling = (isRtl: boolean, theme: ColorTheme) => ({
	"sx": {
		".mainnav__item": {
			display: "block",
			transitionDuration: ".75s",
			transitionTimingFunction: "ease-in-out",
			transitionProperty: "color",
			letterSpacing: "superWide",
			fontSize: "sm",
			fontFamily: "heading",
			textTransform: isRtl ? "none" : "uppercase",
			fontWeight: "600",
			...animations(isRtl, "currentColor", ".35s").underline,

			"&--active": {
				".nav--open &": {
					color: "primary.aramcoBlue" // Always use this color since the BG is dark here
				},

				color: theme.colors.header.activeColor
			},

			"&--open": {
				color: "primary.white"
			},

			"&:hover, &:focus-visible": {
				".nav--open &": {
					color: "primary.aramcoBlue"
				},

				color: theme.colors.header.activeColor
			}
		}
	}
})

export const SubNavigationStyling = (isRtl: boolean) => ({
	"sx": {
		transform: [null, null, null, "none !important"], // OVERRIDING THE FRAMER MOTION ANIMATION ON DESKTOP

		".mainnav--active &": {
			position: "absolute",
			top: [0, null, null, "var(--chakra-sizes-navigationTopSpacing)"],
			left: 0,
			height: [null, null, null, "100vh"],
			width: "100%",
			background: "rgba(31, 31, 31, 0.7)", // TO-DO: COLOR VARIABLE!
			zIndex: 10,
			overflow: "hidden",
		},

		".subnav__block": {
			display: "flex",
			flexBasis: ["100%", null, null, "25%"],
			flexShrink: [0, null, null, 1],
			flexGrow: 0,
			px: ["var(--chakra-sizes-pageMarginSM)", null, null, 8, 16],
			pt: ["10.3125rem", null, null, "6.5rem"],
			pb: ["7.5rem", null, null, "6.5rem"],
			position: ["absolute", null, null, "relative"],
			width: "100%",
			height: "100%",
			background: ["gradients.mobileNavigation", null, null, "transparent"],

			"&.--0": {
				backgroundColor: [null, null, null, "primary.nightGrey.600"],
				zIndex: [null, null, null, 5],

				".subnav__item": {
					textTransform: isRtl ? "none" : ["uppercase", null, null, "none"],
				}
			},
			"&.--1": {
				background: [null, null, null, "primary.nightGrey.500"],
				zIndex: [null, null, null, 4],
			},
			"&.--2": {
				background: [null, null, null, "primary.nightGrey.300"],
				zIndex: [null, null, null, 3],
			},
			"&.--3": {
				background: [null, null, null, "primary.nightGrey.200"],
				zIndex: [null, null, null, 2],
			},
			"&.--4": {
				background: [null, null, null, "primary.nightGrey.100"],
				zIndex: [null, null, null, 1],
			},
		},

		"li": {
			py: ["2", null, null, "0.625rem"],
		},

		"span.nav-text": {
			...animations(isRtl, "currentColor", ".35s").underline,
		},

		".subnav__item": {
			display: "inline-flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
			flexWrap: "wrap",
			width: "100%",
			fontSize: "xs",
			lineHeight: "1.375rem",
			transition: "color .25s ease-in-out",
			letterSpacing: "wider",

			"&--parent": {
				fontSize: isRtl ? ["body", null, null, "1.1875rem"] : ["body", null, null, "0.9375rem"],
				lineHeight: "1.25rem",
				color: "primary.neutralGrey",
				fontWeight: "600",

				"span.nav-text": {
					maxWidth: "100%",
				},
			},

			"&:hover": {
				color: "primary.aramcoBlue",
				position: "relative",
			},

			"&--in-path": {
				color: "primary.aramcoBlue"
			},

			"&--active": {
				color: "primary.white"
			}
		},
		".closebutton": {
			display: ["block", null, null, "none"],
			position: "absolute",
			top: ["7.25rem", null, null, "3.25rem"],
			left: isRtl ? [8, null, null, 8, 16] : "auto",
			right: isRtl ? "auto" : [8, null, null, 8, 16],
			justifyContent: "flex-end",

			"&.show": {
				display: "block"
			}
		},
		".backbutton": {
			fontSize: "xs",
			color: "primary.neutralGrey",
			position: "absolute",
			top: 28,
			left: isRtl ? "auto" : 8,
			right: isRtl ? 8 : "auto",
			display: "flex",
			height: "32px",
			flexWrap: "wrap",
			alignContent: "center",
			alignItems: "center",
		}
	}
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const navBoxBgStyling = (isRtl: boolean) => ({
	"sx": {
		position: "fixed",
		top: "0",
		height: "100vh",
		width: [
			null,
			null,
			"calc((110vw - var(--chakra-sizes-contentWrapperWidthMD)) / 2)",
			"calc((110vw - var(--chakra-sizes-contentWrapperWidthLG)) / 2)",
			"calc((110vw - var(--chakra-sizes-contentWrapperWidthXL)) / 2)",
			"calc((110vw - var(--chakra-sizes-contentWrapperWidth2XL)) / 2)"
		],
		zIndex: "-1",
	}
});
