import React, { FC, useContext } from "react";
import { useSitecoreContext, sc } from "~/foundation/Jss";
import { FooterProps } from "../generated-types";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { Box, Button, Flex, HStack, Text, useMediaQuery } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { useParallax } from "react-scroll-parallax";
import { Icon } from "~/foundation/Components/Icon";
import { useTranslation } from "~/foundation/Dictionary";
import { NavigationDictionary } from "~/feature/Navigation/dictionary";
import { PageThemeBase } from "~/foundation/Theme/generated-types";
import { CustomTooltip } from "~/foundation/Components/CustomTooltip";
import { withFooterTheme } from "~/foundation/Theme/enhancers/withFooterTheme";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";
import { imageSizes } from "~/foundation/Theme";
import { ChakraRouterLink } from "~/foundation/Components/Link/ChakraRouterLink";

export const critical = true;

const Footer: FC<FooterProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext<PageThemeBase>();
	const [t] = useTranslation<NavigationDictionary>();
	const [isMdBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.md})`);
	const [theme] = useContext(ColorThemeContext);
	const { FooterLogo } = theme;

	let parallax = {
		ref: React.createRef<HTMLDivElement>()
	};

	parallax = useParallax<HTMLDivElement>({
		speed: -30,
		translateY: [isMdBreakpointOrAbove ? "-50%" : "-10%", "0%", "easeInOut"],
		shouldAlwaysCompleteAnimation: true,
		rootMargin: { top: isMdBreakpointOrAbove ? 200 : 300, right: 0, bottom: 0, left: 0 }
	});

	const goToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const footerBadge = () => {
		return (
			<Flex maxW="135px" h="auto" w="100%">
				<sc.AdvanceImage
					field={rendering.customData?.footerBadge}
					fieldName="footerBadge"
					itemId={rendering?.dataSource}
					sizes="100vw" // always same size
					srcSet={[imageSizes.footerBadge]}
					editable={sitecoreContext.pageEditing}
					sx={{
						objectPosition: "center center",
						objectFit: ["cover", null, "cover"],
						minWidth: "100%",
						minHeight: "100%"
					}}
					lazy={false}
				/>
			</Flex>
		)
	}

	return (
		<Box as="footer" overflow="hidden">
			<Ribbon
				ref={parallax.ref}
				overflow="hidden"
				bg={theme.colors.footer.bg}
				color="primary.white"
				pt={["10", null, null, "20"]}
				pb={["7", null, null, "14"]}
				className="page-footer">
				<sc.Placeholder name={rendering.placeholderNames.footer_navigation} rendering={rendering} />

				<ContentWrapper py="0" mt={["16", null, null, "4.8rem"]}>
					<Flex flexFlow={['column-reverse', null, 'row']}
						justifyContent={['space-around', null, 'space-between']}
						alignItems={['center', null, 'flex-end']}
					>
						<HStack justifyContent="space-between" alignItems={["center", null, "flex-end"]} w={["100%", null, "calc(50% + 3.4rem)"]}>
							{sitecoreContext.custom.settings.copyrightText &&
								<Text size="xxs" color={theme.colors.footer.color} fontWeight="600" lineHeight="1.5rem">{sitecoreContext.custom.settings.copyrightText}</Text>
							}
							<CustomTooltip label={t("navigation.footer.scrollToTop")}>
								<Button
									onClick={goToTop}
									variant="primary"
									size="md"
									ms="0"
									color={theme.colors.footer.color}
									aria-label={t("navigation.footer.scrollToTop")}>
									<Flex className="icon-wrapper" transitionProperty="color, border" transitionDuration=".3s" transitionTimingFunction="ease-in-out">
										<Icon
											variant="ArrowUp"
											w="icons.md"
										/>
									</Flex>
								</Button>
							</CustomTooltip>
						</HStack>
						{/*Footer Logo*/}
						<Box mb={['7', null, '0']}>
							<RouterLink to="/" title={t("navigation.logoTitle")}>
								<Box w="8.5rem" h="1.75rem">
									<FooterLogo />
								</Box>
							</RouterLink>
						</Box>
					</Flex>

					{(rendering.customData?.footerBadge?.value?.src || sitecoreContext.pageEditing) && (
						<Flex justify={["center", null, "flex-start"]} mt={[12, null, 6]}>
							{rendering.customData?.footerBadgeLink?.value?.href ? (
								<ChakraRouterLink
									variant="unstyled"
									display="block"
									w="fit-content"
									h="fit-content"
									to={rendering.customData?.footerBadgeLink?.value?.href}
									target={rendering.customData?.footerBadgeLink?.value?.target || "_blank"}
								>
									{footerBadge()}
								</ChakraRouterLink>
							) : (
								<>
									{footerBadge()}
								</>
							)}
						</Flex>
					)}
				</ContentWrapper>
			</Ribbon>
		</Box>
	);
};

export default withFooterTheme({ fallbackToPageTheme: true })(Footer);