import { Box, Button, CloseButton, Flex, Input, Modal, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React, { FC, useContext, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Icon } from "~/foundation/Components/Icon";
import { useSitecoreContext } from "~/foundation/Jss";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Ribbon } from "~/foundation/Components/Ribbon";
import svgSrc from "~/foundation/Assets/svg/icons/Close-blue.svg";
import { useTranslation } from "~/foundation/Dictionary";
import { NavigationDictionary } from "../dictionary";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { AnimatePresence } from "framer-motion";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

type SearchProps = {
	mobileView: boolean
}

export const Search: FC<SearchProps> = ({ mobileView }) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const [t] = useTranslation<NavigationDictionary>();
	const [isOpenMobile, setIsOpenMobile] = useState<boolean>(false);
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [theme] = useContext(ColorThemeContext);

	useEffect(() => {
		if (isOpenMobile) {
			inputRef.current?.focus();
		}
	}, [isOpenMobile]);

	const [searchQuery, setSearchQuery] = useState("");

	return (
		<>
			{(sitecoreContext.custom.settings.searchResultPageUrl && sitecoreContext.custom.settings.searchResultPageUrl !== "") &&
			<Box zIndex={20}>
				{mobileView ?
					<Flex>
						{!isOpenMobile &&
							<Button
								onClick={() => setIsOpenMobile(true)}
								border="2px solid transparent"
								title={t("navigation.mainNavigation.searchIconTitle")}
							>
								<Icon
									variant="Search"
									w="icons.lg"
									ms="0"
									sx={{
										"path": {
											"fill": "primary.neutralGrey"
										}
									}}
								/>
							</Button>
						}
						<AnimatePresence>
							{isOpenMobile &&
								<MotionBox
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.2 }}
								>
									<Flex
										bg="gradients.mobileNavigation"
										flexDirection="row"
										flexWrap="wrap"
										alignItems="center"
										alignContent="flex-start"
										justifyContent="space-between"
										position="absolute"
										top="0"
										left="0"
										h="100vh"
										w="100%"
										px="var(--chakra-sizes-pageMarginSM)"
										pt="1.3125rem"
										data-tracking-type="visibility"
										data-tracking-id="search_results"
										data-tracking-data={JSON.stringify({ id: "popup" })}
									>
										<Flex
											as="form"
											action=""
											flexBasis="75%"
											maxW="75%"
											border="2px solid"
											borderColor="primary.neutralGrey"
											borderRadius="2rem"
											onSubmit={(e) => {
												e.preventDefault();

												pushDataLayer(() => ({
													event: "GAevent",
													event_type: "click",
													event_name: "search_results",
													type: "search",
													text: searchQuery,
													group: "All",
													id: "popup"
												}));

												navigate({
													pathname: sitecoreContext.custom.settings.searchResultPageUrl,
													...(searchQuery && {
														search: createSearchParams({
															query: searchQuery
														}).toString()
													})
												});
											}}
										>
											<Button type="submit" title={t("navigation.mainNavigation.searchIconTitle")} onClick={() => setIsOpenMobile(true)}>
												<Icon
													variant="Search"
													w="icons.lg"
													ms="3"
													sx={{
														"path": {
															"fill": "primary.neutralGrey"
														}
													}}
												/>
											</Button>
											<Input
												ref={inputRef}
												type="search"
												placeholder={t("navigation.mainNavigation.searchPlaceHolder")}
												backgroundColor="transparent"
												border="none"
												fontSize="xs"
												w="100%"
												p=".75rem"
												color="primary.white"
												_placeholder={{ color: "primary.aramcoGrey" }}
												_focus={{ "border": "none", "boxShadow": "none" }}
												sx={{
													"&::-webkit-search-cancel-button": {
														backgroundImage: `url(${svgSrc})`,
														backgroundSize: "contain",
														appearance: "none",
														height: "1rem",
														width: "1rem",
														cursor: "pointer",
													},
													"&:hover, &:focus, &:focus-within, &:focus-visible": {
														outline: "none",
														border: "none"
													},
													"&::placeholder": {
														color: theme.colors.tags.color
													}
												}}
												onChange={(e) => setSearchQuery(e.target.value)}
											/>
										</Flex>
										<CloseButton
											onClick={() => setIsOpenMobile(false)}
											position="relative"
											color="primary.aramcoLinkBlue"
										/>
									</Flex>
								</MotionBox>
							}
						</AnimatePresence>
					</Flex>
					:
					<>
						<Box as="button" onClick={onOpen} title={t("navigation.mainNavigation.searchIconTitle")}>
							<Icon
								variant="Search"
								w="icons.lg"
								sx={{
									"path": {
										transitionDuration: ".25s",
										transitionTimingFunction: "aramcoCubic",
										transitionProperty: "fill",
										"fill": isOpen ? "primary.aramcoLinkBlue" : "primary.neutralGrey"
									},
									"&:hover": {
										"path": {
											"fill": "primary.aramcoLinkBlue"
										}
									}
								}}
							/>
						</Box>

						<Modal
							isCentered
							onClose={onClose}
							isOpen={isOpen}
							motionPreset="slideInBottom"
							closeOnOverlayClick={true}
							initialFocusRef={inputRef}
						>
							<ModalOverlay bg="overlay.darkGreyAlpha" />
							<ModalContent zIndex="20" top="var(--chakra-sizes-headerHeightLG)" maxH="6.25rem">
								<Ribbon
									as="div"
									className="search-modal"
									data-tracking-type="visibility"
									data-tracking-id="search_results"
									data-tracking-data={JSON.stringify({ id: "popup" })}
									bgColor="transparent"
								>
									<ContentWrapper py={[0, 0, 0, 0, 0]}>
										<Flex me="1" borderRadius="3.25rem" justifyContent="space-between" alignItems="center" bg="primary.white">
											<Flex as="form" ps="3.875rem" w="100%" onSubmit={(e) => {
												e.preventDefault();
												navigate({
													pathname: sitecoreContext.custom.settings.searchResultPageUrl,
													...(searchQuery && {
														search: createSearchParams({
															query: searchQuery
														}).toString()
													})
												});

												onClose();
											}}>
												<Box display="flex" me="2.25rem">
													<Button type="submit" title={t("navigation.mainNavigation.searchIconTitle")}>
														<Icon
															variant="Search"
															w="icons.lg"
															sx={{
																"path": {
																	"fill": "primary.aramcoLinkBlue"
																}
															}}
														/>
													</Button>
												</Box>

												<Input
													ref={inputRef}
													type="search"
													placeholder={t("navigation.mainNavigation.searchPlaceHolder")}
													w="100%"
													p="1rem 1.75rem"
													borderRadius="2rem"
													border="2px solid"
													borderColor="primary.white"
													fontSize="body"
													color="primary.text"
													sx={{
														"&::-webkit-search-cancel-button": {
															backgroundImage: `url(${svgSrc})`,
															backgroundSize: "contain",
															appearance: "none",
															height: "1rem",
															width: "1rem",
															cursor: "pointer",
														},
														"&:hover, &:focus, &:focus-within, &:focus-visible": {
															outline: "none",
															borderColor: "primary.aramcoLinkBlue"
														}
													}}
													onChange={(e) => setSearchQuery(e.target.value)}
												/>
											</Flex>
											<Box
												bg="primary.aramcoLinkBlue"
												color="primary.white"
												borderLeftRadius={isRtl ? "3.25rem" : 0}
												borderRightRadius={isRtl ? 0 : "3.25rem"}
												p="1.875rem"
												ps="4.625rem"
												h="100%"
												position="relative"
												transform="translateX(4px)"
											>
												<Box
													position="absolute"
													width="3.25rem"
													height="100%"
													left={isRtl ? "auto" : "0"}
													right={isRtl ? "0" : "auto"}
													top="0"
													ms="-2px"
													bgColor="primary.white"
													borderLeftRadius={isRtl ? "3.25rem" : 0}
													borderRightRadius={isRtl ? 0 : "3.25rem"}
												/>
												<CloseButton
													onClick={onClose}
													position="relative"
													w="2.8125rem"
													h="2.8125rem"
													border="2px solid"
													borderColor="primary.white"
													borderRadius="50%"
													transitionDuration=".25s"
													transitionProperty="transform"
													transitionTimingFunction="aramcoCubic"
													_hover={{
														transform: [null, null, null, "rotate(45deg)"]
													}}
													sx={{
														"svg": {
															w: [".75rem", null, null, "1rem"],
															h: [".75rem", null, null, "1rem"]
														}
													}}
												/>
											</Box>
										</Flex>
									</ContentWrapper>
								</Ribbon>
							</ModalContent>
						</Modal>
					</>
				}

			</Box>
			}
		</>
	)
}
