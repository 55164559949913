import React, { FC, useEffect } from "react";
import { ScriptAssetProps } from "../generated-types";

export const critical = true;

const ScriptAsset: FC<ScriptAssetProps> = ({ fields }) => {
	const appendContainer = fields?.domContainerId?.value;

	useEffect(() => {
		const script = document.createElement('script');

		script.innerHTML = fields?.scriptBlock?.value;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	if (!appendContainer) {
		return <></>;
	}

	return <div id={appendContainer} />;
};

export default ScriptAsset;