import React, { useEffect, useState } from "react";
import { Image, Link } from "@chakra-ui/react";
import { useSitecoreContext } from "~/foundation/Jss";
import { debounce } from "~/foundation/Utils/debounce";

export const StickyBadge = () => {
	const { sitecoreContext } = useSitecoreContext();
	const badge = sitecoreContext?.custom?.stickyBadge;

	if (!badge?.image?.value?.src) {
		return null;
	}

	const [open, setOpen] = useState<boolean>(true);
	const [computedClipPath, setComputedClipPath] = useState<string>("");
	const scrollAmount = 160;
	const borderRadius = "5px";
	const transform = "all 0.4s ease-out";
	const hoverClipPath = `inset(0px 0px 0px 0px round ${borderRadius})`;
	const imageWidth = parseFloat(badge?.image?.value?.width ?? "0");
	const imageHeight = parseFloat(badge?.image?.value?.height ?? "0");
	const crop = {
		x: (badge?.image?.value?.cropx ?? 0) * imageWidth,
		y: (badge?.image?.value?.cropy ?? 0) * imageHeight,
	};
	const initialPadding = Math.max(0, imageHeight - crop.y);

	useEffect(() => {
		const debouncedHandleScroll = debounce(() => {
			setOpen(window.scrollY < scrollAmount);
		}, 100);

		window.addEventListener("scroll", debouncedHandleScroll);
		return () => {
			window.removeEventListener("scroll", debouncedHandleScroll);
		};
	}, []);

	useEffect(() => {
		if (imageWidth && imageHeight && crop?.x && crop?.y) {
			setComputedClipPath(`inset(0px ${imageWidth - crop.x}px ${imageHeight - crop.y}px 0px round ${borderRadius} 0 ${borderRadius} 0)`);
		}
	}, [imageWidth, imageHeight, crop?.x, crop?.y]);

	return (
		<>
			<Link
				as={badge?.link?.value?.href ? "a" : "div"}
				position="fixed"
				bottom={0}
				left={0}
				zIndex={5}
				href={badge?.link?.value?.href}
				isExternal={badge?.link?.value?.target === "_blank"}
				borderRadius="md"
				pl={2}
				pb={2}
				mb={open ? 0 : `-${initialPadding}px`}
				_hover={{ mb: 0, pb: 0 }}
				role="group"
				sx={{ transition: transform, transformOrigin: "bottom left" }}
				transform={["scale(0.8)", "scale(1)"]}
			>
				{badge?.image?.value && badge?.image?.value?.src && (
					<Image
						src={badge?.image?.value?.src}
						alt={badge?.image?.value?.alt || "Badge"}
						sx={{ clipPath: open ? hoverClipPath : computedClipPath, transition: transform }}
						_groupHover={{ clipPath: hoverClipPath, marginBottom: 2 }}
					/>
				)}
			</Link>
		</>
	);
};