import { getFullUrl } from "~/foundation/Utils";
import { ApiError } from "./types";
import config from "~/temp/config";

export type Http = typeof http;
export type ApiRequestMethods = "GET" | "PUT" | "POST" | "DELETE";
export type ServiceEndpointMeta = [method: ApiRequestMethods, url: string];
export type Meta<TKeys> = Record<keyof TKeys, ServiceEndpointMeta>;


type FetchData<T> = {
	params?: { [key: string]: any }, // eslint-disable-line
	body?: T,
	queryStrings?: { [key: string]: any }; // eslint-disable-line
};

export type ApiResponse<TSuccess> = {
	statusCode: number;
	data?: TSuccess,
	error?: ApiError | unknown
}

const language = typeof window === "undefined" ? "en" : (document.documentElement.lang || "en");

export const http = {
	fetch: <TResponse, TRequest = unknown>(meta: ServiceEndpointMeta, data?: FetchData<TRequest>): Promise<ApiResponse<TResponse>> => {

		const [method, endpoint] = meta;
		const url = getFullUrl(endpoint);

		const fetchObj: RequestInit = {
			method,
			headers: {
				'content-type': 'application/json;charset=UTF-8',
			}
		};

		if (data?.body) {
			fetchObj.body = JSON.stringify(data.body);
		}

		let finalUrl = url;

		if (data?.params) {
			Object.keys(data.params).forEach(param => {
				finalUrl = url.replace(`/:${param}`, `/${data.params?.[param]}`)
			});
		}

		finalUrl = `${finalUrl}?sc_apikey=${config.sitecoreApiKey}&sc_lang=${language}`;

		if (data?.queryStrings) {
			const queryStrings: string[] = [];
			Object.keys(data.queryStrings).forEach(queryStringKey => {
				const queryStringValue = data.queryStrings?.[queryStringKey];
				if (queryStringValue) {
					queryStrings.push(`${queryStringKey}=${queryStringValue}`);
				}
			});

			finalUrl = `${finalUrl}&${queryStrings.join("&")}`;
		}

		const promise = new Promise<ApiResponse<TResponse>>((resolve, reject) => {
			fetch(finalUrl, fetchObj)
				.then(response => {
					const { status, ok } = response;

					response.json()
						// eslint-disable-next-line
						.then((jsonResponse: any) => {
							if (!ok) {
								if (status === 500) { // InternalServerError
									console.error(jsonResponse);
								}

								reject({
									statusCode: status,
									error: jsonResponse
								});

								return;
							}

							resolve({
								statusCode: status,
								data: jsonResponse
							});
						})
						.catch((error) => {
							reject(error);
						})
				})
				.catch((error) => {
					reject(error);
				})
		});

		return promise;
	},

	get: async <TResponse>(url: string): Promise<ApiResponse<TResponse>> => {

		const fullUrl = getFullUrl(url);
		const response = await fetch(fullUrl);
		const jsonResponse = await response.json();

		if (!response.ok) {
			return {
				statusCode: response.status,
				error: jsonResponse
			}
		}

		return {
			statusCode: response.status,
			data: jsonResponse as TResponse
		}
	},

	post: async <TRequest, TResponse>(url: string, data: TRequest): Promise<ApiResponse<TResponse>> => {
		const fullUrl = getFullUrl(url);
		const response = await fetch(fullUrl, {
			method: "POST",
			headers: {
				'content-type': 'application/json;charset=UTF-8',
			},
			body: JSON.stringify(data)
		});

		const jsonResponse = await response.json();

		if (!response.ok) {
			return {
				statusCode: response.status,
				error: jsonResponse
			}
		}

		return {
			statusCode: response.status,
			data: jsonResponse as TResponse
		}
	},

	put: async <TRequest, TResponse>(url: string, data: TRequest): Promise<ApiResponse<TResponse>> => {
		const fullUrl = getFullUrl(url);
		const response = await fetch(fullUrl, {
			method: "PUT",
			headers: {
				'content-type': 'application/json;charset=UTF-8',
			},
			body: JSON.stringify(data)
		});

		const jsonResponse = await response.json();

		if (!response.ok) {
			return {
				statusCode: response.status,
				error: jsonResponse
			}
		}

		return {
			statusCode: response.status,
			data: jsonResponse as TResponse
		}
	},

	delete: async <TResponse>(url: string): Promise<ApiResponse<TResponse>> => {
		const fullUrl = getFullUrl(url);
		const response = await fetch(fullUrl);
		const jsonResponse = await response.json();

		if (!response.ok) {
			return {
				statusCode: response.status,
				error: jsonResponse
			}
		}

		return {
			statusCode: response.status,
			data: jsonResponse as TResponse
		}
	},
};